/**
 * App Config File
 */
const AppConfig = {
	appLogo: require("Assets/img/np-logo.png"), // App Logo
	brandName: "NP", // Brand Name
	navCollapsed: false, // Sidebar collapse
	darkMode: false, // Dark Mode
	boxLayout: false, // Box Layout
	rtlLayout: false, // RTL Layout
	miniSidebar: false, // Mini Sidebar
	enableSidebarBackgroundImage: true, // Enable Sidebar Background Image
	sidebarImage: require("Assets/img/sidebar-4.jpg"), // Select sidebar image
	isDarkSidenav: true, // Set true to dark sidebar
	enableThemeOptions: false, // Enable Theme Options
	locale: {
		languageId: "english",
		locale: "en",
		name: "English",
		icon: "en",
	},
	enableUserTour: false, // Enable / Disable User Tour
	copyRightText: "", // Copy Right Text
	// light theme colors
	themeColors: {
		primary: "#5D92F4",
		secondary: "#677080",
		success: "#00D014",
		danger: "#FF3739",
		warning: "#FFB70F",
		info: "#00D0BD",
		dark: "#464D69",
		default: "#FAFAFA",
		greyLighten: "#A5A7B2",
		grey: "#677080",
		white: "#FFFFFF",
		purple: "#896BD6",
		yellow: "#D46B08",
	},
	// dark theme colors
	darkThemeColors: {
		darkBgColor: "#424242",
	},

	// apiBaseURL: "http://localhost:55155/api" // local dev
	apiBaseURL: "https://api-npom.intelligent-applications.net/api" // dev server
	//apiBaseURL: "https://stg-api-npom.intelligent-applications.net/api" // stagging server
	//apiBaseURL: "https://api-orders.np.co.tt/api" // prod

	// apiBaseURL:
	// 	process.env.NODE_ENV === "development"
	// 		? "http://localhost:55155/api"
	// 		: "https://api-npom.intelligent-applications.net/api",
};

export default AppConfig;
